import React from 'react';
import './Loader.css'; // Import CSS for styling

const LoaderComponent = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <p>Cargando Datos...</p>
    </div>
  );
};

export default LoaderComponent;