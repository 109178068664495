import React, { useState, useEffect } from "react";
import "./App.css";
import JobData from "./models/jobData";
import "./JobDataList.css"; // Import the CSS file
import { dateFormat } from "./utils/dateFormat";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Navbar from "react-bootstrap/Navbar";
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import ReactGA from 'react-ga';
import LoaderComponent from './LoaderComponent'; // Import Loader component

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



function App() {
  const [jobData, setJobData] = useState<JobData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [paginationRange, setPaginationRange] = useState({ start: 1, end: 5 }); // Initial pagination range

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyChzpsAhF1F2QE8bkLrZR-04KZKf0yhNjQ",
    authDomain: "mep-vacantes.firebaseapp.com",
    projectId: "mep-vacantes",
    storageBucket: "mep-vacantes.appspot.com",
    messagingSenderId: "849000964756",
    appId: "1:849000964756:web:cfb435a8c1735837b50a81",
    measurementId: "G-CXMSZQR1R5"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Analytics
  const analytics = getAnalytics(app);
  const perf = getPerformance(app);

  analytics.app.automaticDataCollectionEnabled = true;
  perf.app.automaticDataCollectionEnabled = true;

  const itemsPerPage = 15;

  ReactGA.initialize('G-CXMSZQR1R5'); // Replace with your Tracking ID

  useEffect(() => {
    ReactGA.pageview("New client page");
  }, []);

  // Function to track click events
  const trackLinkClick = (job: JobData) => {
    ReactGA.event({
      category: 'EnlaceAplicar Click', // Event category
      action: 'Click', // Event action
      label: `${job.especialidad} - ${job.nombreInstitucion} - ${job.direccionRegional}`, // Event label with additional job information
    });
  };

  // useEffect(() => {
  //   fetch("https://us-central1-third-faculty-416222.cloudfunctions.net/hello")
  //     .then((response) => response.json())
  //     .then((data) => setJobData(data))
  //     .catch((error) => console.error("Error fetching job data:", error));
  // }, []);
  useEffect(() => {
    // Set loading to true before making the fetch request
    setLoading(true);

    fetch("https://us-central1-third-faculty-416222.cloudfunctions.net/hello")
      .then((response) => response.json())
      .then((data) => {
        // Set the fetched data and loading to false once the data is received
        setJobData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job data:", error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredJobData = jobData.filter(
    (job) =>
      job.clasePuesto.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.especialidad.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.numeroVacante.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.motivoVacante.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.cantidadLecciones.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.puesto.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.nombreInstitucion.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.direccionRegional.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.vencimiento.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.fechaAplicacion.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.enlaceAplicar.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredJobData.length / itemsPerPage);
  const adjustedCurrentPage = Math.min(currentPage, totalPages); // Ensure currentPage is within valid range
  const startIndex = (adjustedCurrentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredJobData.length);
  const currentItems = filteredJobData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });

  };


  const handleNextButtonClick = () => {
    if (paginationRange.end < totalPages) {
      setPaginationRange(prevRange => ({ start: prevRange.start + 1, end: prevRange.end + 1 }));
    }
  };

  const handlePrevButtonClick = () => {
    if (paginationRange.start > 1) {
      setPaginationRange(prevRange => ({ start: prevRange.start - 1, end: prevRange.end - 1 }));
    }
  };

  return (
    <div>
      {loading ? <LoaderComponent /> :

        <Container>
          <Navbar className="bg-body-tertiary">
            <Container style={{ backgroundColor: "grey" }}>
              <Navbar.Brand href="#home" style={{ color: "white" }}>
                <img
                  src="./logo-mep.png" // Replace "path/to/your/logo.png" with the actual path to your logo image file
                  alt="Logo"
                  height="60"
                  className="d-inline-block align-top mr-2" // Adjust the margin as needed
                />
              </Navbar.Brand>
            </Container>
          </Navbar>
          <Navbar className="bg-body-tertiary">
            <Container style={{ backgroundColor: "black" }}>
              <Navbar.Brand href="#home" style={{ color: "white" }}>
                Posiciones Disponibles MEP
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text style={{ color: "white" }}>
                  Fecha: {dateFormat()}
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Row>
            <Col sm={6}>Postulaciones activas: {jobData.length}</Col>
            <Col sm={6}>
              <div>
                <input
                  type="text"
                  placeholder="Buscar..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="search-input"
                />
              </div>
            </Col>
          </Row>

          {jobData.length === 0 ? <p>Cargando resultados...</p> : null}
          <Row className="mt-3">
            <Col sm={3}></Col>
            <Col sm={6}>
              <ul className="job-list">
                {currentItems.map((job: JobData, index) => (
                  <li key={index}>
                    <Card>
                      <Card.Body>
                        <Card.Title>{job.clasePuesto}</Card.Title>
                        <Card.Text>{job.motivoVacante}</Card.Text>
                      </Card.Body>
                      <ListGroup className="list-group-flush">
                        <ListGroup.Item>
                          Especialidad: {job.especialidad}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Numero de Vacante: {job.numeroVacante}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Cantidad de Lecciones: {job.cantidadLecciones}
                        </ListGroup.Item>
                        <ListGroup.Item>Puesto: {job.puesto}</ListGroup.Item>
                        <ListGroup.Item>
                          Nombre de la institucion: {job.nombreInstitucion}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Dirección Regional: {job.direccionRegional}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Vencimiento: {job.vencimiento}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Fecha de Aplicación: {job.fechaAplicacion}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Body>
                        <Button
                          variant="primary"
                          target="_blank"
                          href={job.enlaceAplicar}
                          onClick={() => trackLinkClick(job)} // Track link click
                        >
                          Enlace de Aplicación
                        </Button>                    {/* <Card.Link href={job.enlaceAplicar} target="_blank">
                      Enlace de Aplicación
                    </Card.Link> */}
                        <Button variant="secondary" target="_blank" href={job.dreLink}>Enlace DRE</Button>{' '}

                        {/* <Card.Link href={job.dreLink} target="_blank">
                      Enlace Regional
                    </Card.Link> */}
                      </Card.Body>
                    </Card>
                    <hr />
                  </li>
                ))}
              </ul>
              <Row>
                <Col sm={12}>
                  <Pagination size="lg">
                    <Pagination.Prev onClick={handlePrevButtonClick} disabled={currentPage === 1} />
                    {[...Array(totalPages)].map((_, i) => {
                      const pageNumber = i + 1;
                      if (pageNumber === currentPage) {
                        return (
                          <Pagination.Item key={i + 1} active onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                          </Pagination.Item>
                        );
                      } else if (pageNumber === currentPage - 1 || pageNumber === currentPage + 1) {
                        return (
                          <Pagination.Item key={i + 1} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                          </Pagination.Item>
                        );
                      } else if (pageNumber === 1 || pageNumber === totalPages) {
                        return (
                          <Pagination.Item key={i + 1} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                          </Pagination.Item>
                        );
                      } else if (pageNumber === 2 && currentPage > 4) {
                        return (
                          <>
                            <Pagination.Ellipsis key={i} />
                            <Pagination.Item onClick={() => handlePageChange(pageNumber)}>
                              {pageNumber}
                            </Pagination.Item>
                          </>
                        );
                      } else if (pageNumber === totalPages - 1 && currentPage < totalPages - 3) {
                        return (
                          <>
                            <Pagination.Item onClick={() => handlePageChange(pageNumber)}>
                              {pageNumber}
                            </Pagination.Item>
                            <Pagination.Ellipsis key={i} />
                          </>
                        );
                      }
                      return null;
                    })}
                    <Pagination.Next onClick={handleNextButtonClick} disabled={currentPage === totalPages} />
                  </Pagination>
                </Col>
              </Row>

            </Col>
            <Col sm={12}></Col>
          </Row>

          <Navbar className="bg-body-tertiary">
            <Container style={{ backgroundColor: "black" }}>
              <Navbar.Toggle />
              <Row>
                <Col sm={12}>
                  <Navbar.Text style={{ color: "white" }}>
                    Derechos Reservados © 2024
                    <a href="mailto:kevin.rodriguez@ucrso.info"
                      style={{ color: "white" }}> Kevin Rodriguez</a>
                  </Navbar.Text>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Navbar.Text style={{ color: "white", fontSize: "10px" }}>
                    Pagina no oficial creada para que los usuarios tengan una mayor facilidad en la busqueda de empleos del MEP.

                  </Navbar.Text>
                </Col>
              </Row>
            </Container>
          </Navbar>

        </Container>
      }
    </div>

  );
}

export default App;
